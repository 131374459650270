import pdf from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import fileDownload from 'js-file-download';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  Table,
  Stack,
  Dialog,
  Button,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  DialogTitle,
  CardContent,
  DialogContent,
  TableContainer
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { fDateTime } from 'src/utils/formatTime';

import { useSelector } from 'src/redux/store';
import { ValidationData, ValidationStatus } from 'src/@types/documents/validationReport';

import Mobile from 'src/components/Mobile';
import Iconify from 'src/components/Iconify';
import Desktop from 'src/components/Desktop';
import Scrollbar from 'src/components/Scrollbar';

const { Page, Text, View, Font, Document, StyleSheet } = pdf;

// ----------------------------------------------------------------------

type DocumentValidationReportModalProps = {
  data: ValidationData;
  handleClose: () => void;
};

type DataRowProps = {
  field: string;
  value?: string;
};

const styles = StyleSheet.create({
  page: {
    padding: 5,
    fontFamily: 'Roboto',
  },
  section: {
    margin: 5,
    padding: 5,
  },
  viewer: {
    width: '100%',
    height: window.innerHeight,
  },
  text: {
    fontSize: 14,
  },
  approverText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: 18,
    textAlign: 'center',
    margin: 10,
  },
});

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/webfonts/fa-solid-900.ttf',
});

export default function DocumentValidationReportModal({
  data,
  handleClose,
}: DocumentValidationReportModalProps) {
  const { t } = useLocales();
  const { document } = useSelector((state) => state.document);
  const theme = useTheme();

  // eslint-disable-next-line react/no-unstable-nested-components
  const DataRow = ({ field, value }: DataRowProps) => (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">{t(field)}</Typography>
      </TableCell>
      <TableCell sx={{ textAlign: 'end' }}>
        <Typography>{value}</Typography>
      </TableCell>
    </TableRow>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const Status = () => {
    switch (data.validationResult) {
      case ValidationStatus.Valid: {
        return (
          <Typography color={theme.palette.success.main} sx={{ verticalAlign: 'center' }}>
            <Iconify mb="-6px" width={24} height={24} icon="eva:checkmark-circle-2-outline" />{' '}
            {t('documents.validationReport.valid')}
          </Typography>
        );
      }
      case ValidationStatus.NoSignatures: {
        return (
          <Typography color='secondary' sx={{ verticalAlign: 'center' }}>
            <Iconify mb="-6px" width={24} height={24} icon="eva:checkmark-circle-2-outline" />{' '}
            {t('documents.validationReport.noSignatures')}
          </Typography>
        );
      }
      case ValidationStatus.Invalid:
      default: {
        return (
          <Typography color={theme.palette.error.main} sx={{ verticalAlign: 'center' }}>
            <Iconify mb="-6px" width={24} height={24} icon="eva:close-circle-outline" />{' '}
            {t('documents.validationReport.invalid')}
          </Typography>
        );
      }
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const PdfRow = ({ field, value }: DataRowProps) => (
    <View>
      <Text>
        {t(field)}: {value}
      </Text>
    </View>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const PdfEmptyLine = () => (
    <View>
      <Text> </Text>
    </View>
  );

  // renames helps for react18 for types
  const DocumentRe: any = Document;
  const PageRe: any = Page;

  // eslint-disable-next-line react/no-unstable-nested-components
  const PdfContent = () => (
    <DocumentRe>
      <PageRe size="A4" style={styles.page}>
        <PdfRow field="documents.validationReport.documentName" value={data.name} />
        <PdfRow
          field="documents.validationReport.signaturesCount"
          value={data.simpleReport?.signaturesCount.toString()}
        />
        <PdfRow
          field="documents.validationReport.validSignaturesCount"
          value={data.simpleReport?.validSignaturesCount.toString()}
        />
        <PdfRow
          field="documents.validationReport.validationTime"
          value={fDateTime(data.simpleReport?.validationTime || '')}
        />
        <PdfRow
          field="documents.validationReport.validationPolicy"
          value={data.simpleReport?.validationPolicy?.policyName}
        />
        <PdfEmptyLine />
        {!!data.simpleReport?.signatureOrTimestamp?.length && (
          <>
            <PdfRow field="documents.validationReport.signatures" value="" />
            <PdfEmptyLine />
            {data.simpleReport?.signatureOrTimestamp?.map((item, index) => (
              <>
                <PdfRow
                  field="documents.validationReport.signedBy"
                  value={item.signature?.signedBy}
                />
                <PdfRow
                  field="documents.validationReport.signingTime"
                  value={fDateTime(item.signature?.signingTime || '')}
                />
                <PdfRow
                  field="documents.validationReport.signatureFormat"
                  value={item.signature?.signatureFormat}
                />
                <PdfRow
                  field="documents.validationReport.signatureLevel"
                  value={item.signature?.signatureLevel?.value}
                />
                <PdfRow
                  field="documents.validationReport.certificateExpirationDate"
                  value={item.signature?.certificateExpirationDate}
                />
                <PdfEmptyLine />
              </>
            ))}
          </>
        )}
      </PageRe>
    </DocumentRe>
  );

  const onDownload = async () => {
    const pdf = await ReactPDF.pdf(<PdfContent />).toBlob();
    fileDownload(pdf, `${(document?.name || '') + fDateTime(new Date())}.pdf`);
  };

  return (
    <Dialog fullWidth open onClose={handleClose} PaperProps={{
      sx: {
        zIndex: (theme) => theme.zIndex.modal + 1001,
      }
    }}>
      <DialogTitle sx={{ mb: 2 }}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">

          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Typography 
              variant="h5" 
              sx={{ 
                mr: 2, 
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                wordWrap: 'break-word'
              }}>
              {t('documents.validationReport.validationReport')}
            </Typography>
          </Box>

          <Status />

          <IconButton size="small" onClick={handleClose} id="m8mf2ji1">
            <CloseIcon fontSize="small" />
          </IconButton>

        </Stack>
      </DialogTitle>
      <DialogContent>
        <Scrollbar>
          <TableContainer sx={{ mb: 3 }}>
            <Table>
              <TableBody>
                <DataRow field="documents.validationReport.documentName" value={data.name} />
                <DataRow
                  field="documents.validationReport.signaturesCount"
                  value={data.simpleReport?.signaturesCount.toString()}
                />
                <DataRow
                  field="documents.validationReport.validSignaturesCount"
                  value={data.simpleReport?.validSignaturesCount.toString()}
                />
                <DataRow
                  field="documents.validationReport.validationTime"
                  value={fDateTime(data.simpleReport?.validationTime || '')}
                />
                <DataRow
                  field="documents.validationReport.validationPolicy"
                  value={data.simpleReport?.validationPolicy?.policyName}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <Desktop>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}> 
            <Button
              onClick={onDownload}
              type="button"
              variant="contained"
              color="secondary"
              sx={{ mb: 1, mr: 1 }}
              startIcon={  <Iconify
                icon="eva:download-fill"
                width={28}
                height={28}
              />}
              id="rhutleok"
            >
              {t('documents.buttons.download')}
            </Button>
            </Box>
       
          </Desktop>

          <Mobile>
            <Button
              onClick={onDownload}
              fullWidth
              type="button"
              variant="contained"
              color="secondary"
              sx={{ mb: 1 }}
              startIcon={  <Iconify
                icon="eva:download-fill"
                width={28}
                height={28}
              />}
              id="l4p4n09m"
            >
              {t('documents.buttons.download')}
            </Button>
          </Mobile>

          {!!data.simpleReport?.signatureOrTimestamp?.length && (
            <>
              <Typography variant="h6">{t('documents.validationReport.signatures')}</Typography>
              {data.simpleReport?.signatureOrTimestamp?.map((item, index) => (
                <Card key={index} sx={{ border: '1px solid #AAAAAA30', mt: 2 }}>
                  <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                    <TableContainer sx={{ minWidth: 240 }}>
                      <Table>
                        <TableBody>
                          <DataRow
                            field="documents.validationReport.signedBy"
                            value={item.signature?.signedBy}
                          />
                          <DataRow
                            field="documents.validationReport.signingTime"
                            value={fDateTime(item.signature?.signingTime || '')}
                          />
                          <DataRow
                            field="documents.validationReport.signatureFormat"
                            value={item.signature?.signatureFormat}
                          />
                          <DataRow
                            field="documents.validationReport.signatureLevel"
                            value={item.signature?.signatureLevel?.value}
                          />
                          <DataRow
                            field="documents.validationReport.certificateExpirationDate"
                            value={fDateTime(item.signature?.certificateExpirationDate || '')}
                          />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
        </Scrollbar>
      </DialogContent>
    </Dialog>
  );
}
