export enum Permissions {
  ComposeDocument = 1,
  ManageSigners = 2,
  UseTemplates = 3,
  UseHRModule = 4,
  UseAccountingModule = 5,
  UseAddressBook = 6,
  VoidDocument = 7,
  ViewCalendar = 8,
  ViewFolderTree = 9,
}
