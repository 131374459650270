import React from 'react';

import { Box, Paper, useTheme, Typography } from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

import UploadDocumentBtn from 'src/sections/shared/UploadDocumentBtn';

import { Permissions } from '../../@types/auth/constants/permissions';
import PermissionCheck from '../../components/access-checks/PermissionCheck';

//---------------------------------------------------

export default function DashboardWelcomeUpload() {
  const { t } = useLocales();
  const isMobile = useResponsive('down', 'sm');
  const { user } = useAuth();
  const theme = useTheme();

  return (
    <Paper
      sx={{
        width: `100%`,
        py: 2,
        px: isMobile ? 2 : 4,
        margin: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: !isMobile ? 'row' : 'column',
        gap: 2,
        borderRadius: '16px',
        backgroundColor: theme.palette.secondary.main,
        backgroundImage: 'url(/images/dlx_bg_pattern.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      elevation={3}
    >
      <Box>
        <Typography variant="h5" sx={{ color: theme.palette.grey[100] }}>
          {user
            ? t('dashboard.welcomeName', { userName: user?.firstName })
            : t('dashboard.welcome')}
        </Typography>
      </Box>

      <PermissionCheck permissions={[Permissions.ComposeDocument]}>
        <Box sx={{ mt: 1 }}>
          <UploadDocumentBtn />
        </Box>
      </PermissionCheck>
    </Paper>
  );
}
