import qs from 'qs';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useState, ReactNode, useEffect, createContext } from 'react';

import { PATH_AUTH } from 'src/routes/paths';

import useLocales from 'src/hooks/useLocales';

import { IdleTimer } from 'src/utils/idleTimer';

import { AuthUser } from 'src/@types/auth/user';
import { RegistrationRequest } from 'src/@types/auth/auth';
import { clearSession } from 'src/redux/slices/sessionControlCode';

import CountdownTimer from '../components/CountdownTimer';
import { AUTH0_API, AUTO_LOGOUT_MINUTES } from '../config';
import { TabHeartbeatTimer } from '../utils/tabHeartbeatTimer';
// utils
import identityClient from '../utils/httpClients/identityAxios';
import { useConfirmationModalContext } from './ConfirmationModalContext';
import { getSession, setSession, decodeToken, isValidToken } from '../utils/auth/jwt';
// @types
import {
  AuthState,
  JWTContextType,
  SkidInitRequest,
  CodeVerification,
  ResetPasswordRequest,
} from '../@types/auth/auth';

// ----------------------------------------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: getSession()?.user,
};

const AuthContext = createContext<JWTContextType | null>(null);

const localStorageKey = 'sp-auth';

function AuthProvider({ children }: { children: ReactNode }) {
  const [authState, setAuthState] = useState(initialState);
  const navigate = useNavigate();
  const { i18n, onChangeLang, t } = useLocales();
  const modalContext = useConfirmationModalContext();
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get('redirect');

  useEffect(() => {
    const initialize = async () => {
      const { accessToken } = getSession();

      if (accessToken && isValidToken(accessToken)) {
        let { user } = getSession();
        if (!user) {
          user = decodeToken(accessToken);
        }

        setSession({ ...getSession(), user });

        setLocalStorageState({
          isInitialized: true,
          isAuthenticated: true,
          user,
        });
      } else {
        setLocalStorageState({
          isInitialized: true,
          isAuthenticated: false,
          user: null,
        });
      }
    };

    // Storage event is triggered from other tabs only
    window.addEventListener('storage', onStorageUpdate);
    const tabHeartbeatTimer = new TabHeartbeatTimer(() => {
      logout();
    });
    initialize();
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
      tabHeartbeatTimer.cleanUp();
    };
  }, []);

  useEffect(() => {
    if (authState.isAuthenticated) {
      const idleTimer = new IdleTimer(
        60 * AUTO_LOGOUT_MINUTES,
        () => {
          logout();
          modalContext.closeConfirmation();
        },
        onSessionExpirePopup
      );

      return () => {
        idleTimer.cleanUp();
      };
    }
  }, [authState]);

  const onSessionExpirePopup = async () => {
    const autoLogoutExpiredTime = parseInt(
      localStorage.getItem('autoLogoutExpiredTime') || '0',
      10
    );

    const popupResult = await modalContext.showConfirmation(
      <CountdownTimer
        logout={() => logout()}
        closeConfirmation={() => modalContext.closeConfirmation()}
        sessionExpireTime={autoLogoutExpiredTime}
      />,
      ''
    );
    if (!popupResult) {
      logout();
    }
  };

  const onStorageUpdate = (e: any) => {
    const { key, newValue } = e;
    if (key === localStorageKey) {
      const newState = JSON.parse(newValue) as AuthState;
      newState.user = newState.user ? new AuthUser(newState.user) : null;
      setAuthState(newState);
    }
  };

  const setLocalStorageState = (state: AuthState) => {
    setAuthState(state);
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  };

  const login = async (username: string, password: string) => {
    const grant_type = 'password';
    const client_id = AUTH0_API.clientId;
    const params = {
      username,
      password,
      grant_type,
      client_id,
      redirect,
    };

    const key = await initLogin(params);
    return key;
  };

  const smartIdLogin = async (request: SkidInitRequest) => {
    const grant_type = 'smart_id';
    const client_id = AUTH0_API.clientId;
    const params = {
      ...request,
      grant_type,
      client_id,
      redirect,
    };

    const key = await initLogin(params);
    return key;
  };

  const mobileIdLogin = async (request: SkidInitRequest) => {
    const grant_type = 'mobile_id';
    const client_id = AUTH0_API.clientId;
    const params = {
      ...request,
      grant_type,
      client_id,
      redirect,
    };

    const key = await initLogin(params);
    return key;
  };

  const eparakstsLogin = async (request: SkidInitRequest) => {
    const grant_type = 'eparaksts_mobile';
    const client_id = AUTH0_API.clientId;
    const params = {
      ...request,
      grant_type,
      client_id,
      redirect,
    };

    const key = await initLogin(params);
    return key;
  };

  const initLogin = async (params: any) => {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Language': i18n.language,
      },
    };

    try {
      const response = await identityClient.post(
        '/token/authentication/init',
        qs.stringify(params),
        config
      );
      const { key } = response.data;

      if (!key) {
        setToken(response);
        clearSession();
        return null;
      }

      setLocalStorageState({
        ...authState,
        ...{
          isAuthenticated: false,
          user: null,
        },
      });

      return key;
    } catch (error: any) {
      throw error;
    }
  };

  const verifyCode = async (model: CodeVerification) => {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    try {
      const response = await identityClient.post(
        '/token/authentication/authenticate',
        qs.stringify(model),
        config
      );
      setToken(response);
      onChangeLang(i18n.language);
    } catch (error: any) {
      throw error;
    }
  };

  const register = async (request: RegistrationRequest) => {
    const config = {
      headers: {
        'Accept-Language': i18n.language,
      },
    };

    const response = await identityClient.post(
      '/api/registration',
      {
        ...request,
        redirect,
      },
      config
    );

    setLocalStorageState({
      ...authState,
      ...{
        isAuthenticated: false,
        user: null,
      },
    });
  };

  const logout = () => {
    setSession(null);

    if (authState.isAuthenticated) {
      navigate(PATH_AUTH.login);
    }

    setLocalStorageState({
      ...authState,
      ...{
        isAuthenticated: false,
        user: null,
      },
    });
  };

  const resetPassword = async (request: ResetPasswordRequest) => {
    const config = {
      headers: {
        'Accept-Language': i18n.language,
      },
    };

    const response = await identityClient.post('/api/password/reset', request, config);

    navigate('/auth/login', { replace: true });

    setLocalStorageState({
      ...authState,
      ...{
        isAuthenticated: false,
        user: null,
      },
    });
  };

  const passwordRecovery = async (email: string) => {
    const config = {
      headers: {
        'Accept-Language': i18n.language,
        'Content-Type': 'application/json',
      },
    };

    try {
      await identityClient.post('/api/password/recovery', email, config);
    } catch (error: any) {
      throw error;
    } finally {
      navigate(redirect ? `/auth/login?redirect=${redirect}` : '/auth/login', { replace: true });
    }
  };

  const refreshToken = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const grant_type = 'refresh_token';
    const client_id = AUTH0_API.clientId;
    const refresh_token = getSession().refreshToken;
    const params = {
      refresh_token,
      grant_type,
      client_id,
      redirect,
    };

    try {
      const response = await identityClient.post('/connect/token', qs.stringify(params), config);
      setToken(response);
    } catch (error: any) {
      setSession(null);
      setLocalStorageState({
        ...authState,
        ...{
          isAuthenticated: false,
          user: null,
        },
      });
      navigate('/auth/login', { replace: true });
      throw error;
    }
  };

  const confirmEmail = async (token: string, email: string, authType: number) => {
    const params = {
      token,
      email,
      authType,
      redirect,
    };

    try {
      await identityClient.put('api/confirmation', params);
    } catch (error: any) {
      throw error;
    }
  };

  function setToken(response: any) {
    const accessToken = response.data.access_token;
    const refreshToken = response.data.refresh_token;
    const user = decodeToken(accessToken);

    setSession({ accessToken, refreshToken, user });
    setLocalStorageState({
      ...authState,
      ...{
        isAuthenticated: true,
        user,
      },
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        method: 'jwt',
        login,
        verifyCode,
        smartIdLogin,
        mobileIdLogin,
        eparakstsLogin,
        logout,
        register,
        resetPassword,
        passwordRecovery,
        refreshToken,
        confirmEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
